import React from "react"
import { compoundRoutePath } from "../utils"
import { IMenuListItem } from "../service/Menu"
import { EGlobalKey, getGlobalData } from "../components/GlobalData"
import { EAdminType, IAdminInfo } from "../service/Admin"
import { AuditOutlined } from "@ant-design/icons"

export interface IRouter {
	path: string
	component: any
	menu?: boolean
	title: string
	menuTitle?: string
}

export interface IMenuItem {
	title: string
	path?: string
	icon?: React.ReactNode
	routes: IRouter[]
}

const menus: IMenuItem[] = [
	{
		title: "账号",
		icon: React.createElement(AuditOutlined),
		routes: [
			{
				title: "后台账号",
				component: React.lazy(() => import("../views/Account")),
				path: compoundRoutePath("/backaccount"),
				menu: true,
			},
			{
				title: "用户详情",
				component: React.lazy(() => import("../views/Account/Detail")),
				path: compoundRoutePath("/backaccount/detail"),
			},
		],
	},

	{
		title: "系统",
		icon: React.createElement(AuditOutlined),
		routes: [
			{
				title: "角色",
				component: React.lazy(() => import("../views/System/Role/index")),
				path: compoundRoutePath("/role"),
				menu: true,
			},
			{
				title: "新建角色",
				component: React.lazy(() => import("../views/System/Role/index/EditRole")),
				path: compoundRoutePath("/role/add"),
			},
			{
				title: "修改角色",
				component: React.lazy(() => import("../views/System/Role/index/EditRole")),
				path: compoundRoutePath("/role/edit"),
			},

			{
				title: "组织架构",
				component: React.lazy(() => import("../views/System/OrgStructure/index")),
				path: compoundRoutePath("/orgstructure"),
				menu: true,
			},

			{
				title: "菜单",
				component: React.lazy(() => import("../views/System/MenuManage")),
				path: compoundRoutePath("/menumanage"),
				menu: true,
			},

			{
				title: "新增菜单",
				component: React.lazy(() => import("../views/System/MenuManage/EditMenu")),
				path: compoundRoutePath("/menumanage/add"),
			},

			{
				title: "修改菜单",
				component: React.lazy(() => import("../views/System/MenuManage/EditMenu")),
				path: compoundRoutePath("/menumanage/edit"),
			},

			{
				title: "分类列表",
				component: React.lazy(() => import("../views/BackCategory")),
				path: compoundRoutePath("/backcategory"),
				menu: true,
			},
			{
				title: "供应商列表",
				component: React.lazy(() => import("../views/Supplier")),
				path: compoundRoutePath("/supplier"),
				menu: true,
			},
		],
	},

	{
		title: "商品",
		icon: React.createElement(AuditOutlined),
		routes: [
			{
				title: "券管理",
				component: React.lazy(() => import("../views/Coupon")),
				path: compoundRoutePath("/coupon"),
				menu: true,
			},

			{
				title: "修改券",
				component: React.lazy(() => import("../views/Coupon/EditCoupon")),
				path: compoundRoutePath("/coupon/edit"),
			},

			{
				title: "新建券",
				component: React.lazy(() => import("../views/Coupon/EditCoupon")),
				path: compoundRoutePath("/coupon/add"),
			},

			{
				title: "绑定商品",
				component: React.lazy(() => import("../views/Coupon/BindGoods")),
				path: compoundRoutePath("/coupon/bind"),
			},

			{
				title: "商品管理",
				component: React.lazy(() => import("../views/Merchandise")),
				path: compoundRoutePath("/merchandise"),
				menu: true,
			},

			{
				title: "修改商品",
				component: React.lazy(() => import("../views/Merchandise/EditSku")),
				path: compoundRoutePath("/merchandise/edit"),
			},

			{
				title: "新建商品",
				component: React.lazy(() => import("../views/Merchandise/EditSku")),
				path: compoundRoutePath("/merchandise/add"),
			},

			{
				title: "仓库",
				component: React.lazy(() => import("../views/Warehouse")),
				path: compoundRoutePath("/warehouse"),
				menu: true,
			},

			{
				title: "新建仓库",
				component: React.lazy(() => import("../views/Warehouse/EditWarehouse")),
				path: compoundRoutePath("/warehouse/add"),
			},

			{
				title: "编辑仓库",
				component: React.lazy(() => import("../views/Warehouse/EditWarehouse")),
				path: compoundRoutePath("/warehouse/edit"),
			},

			{
				title: "库存管理",
				component: React.lazy(() => import("../views/Storage")),
				path: compoundRoutePath("/storage"),
				menu: true,
			},
			{
				title: "库存记录",
				component: React.lazy(() => import("../views/Storage/InStockLog")),
				path: compoundRoutePath("/storage/instocklog"),
			},

			{
				title: "库存变动",
				component: React.lazy(() => import("../views/Storage/StockChangeLog")),
				path: compoundRoutePath("/storage/instocklog/out"),
			},

			{
				title: "出库单列表",
				component: React.lazy(() => import("../views/OutStorage")),
				path: compoundRoutePath("/outstorage"),
				menu: true,
			},
			{
				title: "出库单明细",
				component: React.lazy(() => import("../views/OutStorage/OutStorageDetail")),
				path: compoundRoutePath("/outstorage/detail"),
			},

			{
				title: "委外出库",
				component: React.lazy(() => import("../views/OutStorage/OutsourcingWarehouse")),
				path: compoundRoutePath("/outsourcingwarehouse"),
			},

			{
				title: "入库单列表",
				component: React.lazy(() => import("../views/InStorage")),
				path: compoundRoutePath("/instorage"),
				menu: true,
			},
			{
				title: "入库",
				component: React.lazy(() => import("../views/InStorage/AddNewStorage")),
				path: compoundRoutePath("/instorage/add"),
			},

			{
				title: "入库单详情",
				component: React.lazy(() => import("../views/InStorage/InStorageDetail")),
				path: compoundRoutePath("/instorage/detail"),
			},
		],
	},

	{
		title: "订单管理",
		icon: React.createElement(AuditOutlined),
		routes: [
			{
				title: "订单列表",
				component: React.lazy(() => import("../views/Order")),
				path: compoundRoutePath("/order"),
				menu: true,
			},

			{
				title: "需求列表",
				component: React.lazy(() => import("../views/Order/DemandList")),
				path: compoundRoutePath("/demandlist"),
				menu: true,
			},

			{
				title: "订单详情",
				component: React.lazy(() => import("../views/Order/OrderDetail")),
				path: compoundRoutePath("/order/detail"),
			},
			{
				title: "超时未发货",
				component: React.lazy(() => import("../views/Order/ExpireOrderList")),
				path: compoundRoutePath("/expireorder"),
				menu: true,
				/* order:list */
			},
			{
				title: "失败订单",
				component: React.lazy(() => import("../views/Order/FailureOrderList")),
				path: compoundRoutePath("/failureorder"),
				menu: true,
			},

			{
				title: "失败需求",
				component: React.lazy(() => import("../views/Order/FailureDemandList")),
				path: compoundRoutePath("/failuredemand"),
				menu: true,
			},

			{
				title: "待处理工单",
				component: React.lazy(() => import("../views/Order/ProcessJkOrderList")),
				path: compoundRoutePath("/processjkList"),
				menu: true,
			},
		],
	},

	{
		title: "售后工单",
		icon: React.createElement(AuditOutlined),
		routes: [
			{
				title: "售后列表",
				component: React.lazy(() => import("../views/AfterSales")),
				path: compoundRoutePath("/aftersales"),
				menu: true,
			},
			{
				title: "创建售后工单",
				component: React.lazy(() => import("../views/AfterSales/EditAfterSales")),
				path: compoundRoutePath("/addaftersale"),
			},
			{
				title: "售后工单详情",
				component: React.lazy(() => import("../views/AfterSales/AfterSaleDetail")),
				path: compoundRoutePath("/aftersaledetail"),
			},

			{
				title: "审核不通过工单",
				component: React.lazy(() => import("../views/AfterSales/RejectAfterSaleDetail")),
				path: compoundRoutePath("/rejectaftersale"),
			},

			{
				title: "退款申请列表",
				component: React.lazy(() => import("../views/AfterSales/RefundList")),
				path: compoundRoutePath("/returnlist"),
				menu: true,
			},

			{
				title: "退款申请详情",
				component: React.lazy(() => import("../views/AfterSales/RefundDetail")),
				path: compoundRoutePath("/returndetail"),
			},

			{
				title: "供应商退款单",
				component: React.lazy(() => import("../views/AfterSales/SupplierRefundList")),
				path: compoundRoutePath("/supplierrefundlist"),
				menu: true,
			},

			{
				title: "上游异常列表",
				component: React.lazy(() => import("../views/AfterSales/SupplierExceptionList")),
				path: compoundRoutePath("/supplierexceptionlist"),
				menu: true,
			},

			{
				title: "下游异常列表",
				component: React.lazy(() => import("../views/AfterSales/CustomerExceptionList")),
				path: compoundRoutePath("/customerexceptionlist"),
				menu: true,
			},
		],
	},
]

interface IResourceItem {
	resourceId: number
	resourceName?: string
	resourceUrl?: string
}

const _setMenus = (resource: any[]) => {



	return resource.reduce((list: IResourceItem[], item) => {
		const { resourceId, resourceUrl, resourceName, child } = item
		list.push({ resourceId, resourceUrl, resourceName })
		child && (list = [...list, ..._setMenus(child)])
		return list
	}, [])
}

/*设置菜单权限*/
export const setMenus = (resource: any[] | null) => {
	const list = resource && _setMenus(resource)
	//permissions = list
	localStorage.setItem("menu", JSON.stringify({ menu: list, timestamp: new Date().getTime() }))
}

export const isAdmin = () => {
	const admin = getGlobalData<IAdminInfo>(EGlobalKey.admin)
	return admin?.user?.accountType === EAdminType.admin
}

/*操作权限列表*/
export const getActionPermissionList = () => {
	const admin = getGlobalData<IAdminInfo>(EGlobalKey.admin)
	if (admin) {
		return Array.from(new Set(admin.menuList.map((item: { permission: any }) => item.permission).filter((item: any) => item)))
	}
	return []
}

/*获取菜单权限*/
const getMenus = () => {

	const jsonStr = localStorage.getItem("menu")
	let permissions: IResourceItem[] = []

	if (jsonStr) {
		permissions = JSON.parse(jsonStr).menu
		if (!permissions) {
			return menus
		}
	}
	const urlList = permissions.filter(p => !!p.resourceUrl)



	return menus.reduce((list: IMenuItem[], item) => {
		const routes = item.routes.filter(route => urlList.some(p => p.resourceUrl === route.path))
		if (routes && routes.length) {
			list.push({ ...item, routes })
		}
		return list
	}, [])
}

export interface IPromiseMenuListItem {
	title: string
	id: string
	path?: string
	icon?: string
	routes: { title: string; path: string }[]
}

export const generateMenus = (menuList: IMenuListItem[]) => {
	const list: IPromiseMenuListItem[] = menuList
		.sort((prev, next) => prev.sort - next.sort)
		.filter(item => item.level === 1)
		.map(item => ({
			title: item.title,
			id: item.menuId,

			routes: [],
		}))

	menuList
		.filter(item => item.level === 2 && !!item.component)
		.forEach(item => {
			const menu = list.find(menu => item.pid === menu.id)
			if (menu) {
				menu.routes.push({
					title: item.title,
					path: compoundRoutePath(item.component),
				})
			}
		})

	return list.filter(item => item.routes.length)

	/*return getMenus().reduce((list: IMenuItem[], item) => {
		const routes = item.routes.filter(route => route.menu)
		if (routes.length) {
			list.push({title: item.title, icon: item.icon, routes})
		}
		return list
	}, [])*/
}

export const generateRoutes = (includeMenu?: boolean) => {
	const adminInfo = getGlobalData<IAdminInfo>(EGlobalKey.admin),
		list = adminInfo?.menuList || [],
		pathList = list.filter(item => !!item.component),
		menuList = list.filter(item => item.level === 1),
		secondLevelList = list.filter(item => item.level === 2),
		/* thirdPidList = pathList.filter(item => item.level === 3).map(item => {
			const res = secondLevelList.find(({ menuId }) => item.menuId === menuId)
			if (res) return { menuId: item.menuId, pid: item.pid, topId: res.pid }
			return null
		}).filter(item => !!item), */

		topList = secondLevelList.map(item => {
			const topItem = menuList.find(({ menuId }) => item.pid === menuId)
			return {
				menuTitle: topItem?.title, path: compoundRoutePath(item.component)
			}
		})

	return getMenus().reduce((list: IRouter[], item) => {

		//const menuTitle = topList.find(topItem => item.routes.some(route => route.path === topItem.path))?.menuTitle

		const routes = includeMenu
			? item.routes.map(route => {
				route.menuTitle = topList.find(topItem => route.path === topItem.path)?.menuTitle ?? item.title
				return route
			})
			: item.routes
		if (routes.length) {
			return [...list, ...routes]
		}
		return list
	}, [])
}
