import Axios from "axios"
import { getToken, setToken } from "./token"
import { message } from "antd"
import { removeLoading } from "../components/Loading"
import { isEmpty, isObject } from "./index"
import JSONBig from "json-bigint"
import { UploadProgressEvent } from "rc-upload/lib/interface"
import { JumpToLogin } from "../components/GlobalData"

//const API_TYPE: 'DEV' | "MOCK" = 'DEV'



type IContentType =
	| "text/html"
	| "text/plain"
	| "text/xml"
	| "image/gif"
	| "image/jpeg"
	| "image/png"
	| "application/xhtml+xml"
	| "application/xml"
	| "application/atom+xml"
	| "application/json"
	| "application/pdf"
	| "application/msword"
	| "application/octet-stream"
	| "application/x-www-form-urlencoded"
	| "multipart/form-data"

export interface IAjaxPage {
	pageNum: number
	pageSize: number
}

export interface IAjaxDownUrl {
	downurl: string
}

export interface IAjaxResponse<T> {
	code: number
	data: T
	message: string
}

//export const apiUrl = 'http://cptbapi.test.chepeilong.cn/01/';
//'http://192.168.2.137:8080/01/'

// export const apiUrl = ID_DEV ? 'http://192.168.2.137:8081/01/':
//   window.location.href.indexOf('store.chepeilong.com') >= 0 ||
//   window.location.href.indexOf('store.chepeitong.com') >= 0?
//     'http://storeapi.chepeilong.com/01/':
//     'http://cptbapi2.test.chepeilong.cn/01/'

/*'http://192.168.2.137:8080/01/'*/
export const ApiHost =
	process.env.NODE_ENV === "development"
		? "http://localhost:3000"
		: /* : window.location.host.indexOf("admin.dadi18.com") === 0? "https://mall.dadi18.com" */
		`${window.location.protocol}//${window.location.host}`
//export const ApiHost = process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : process.env.BUILD_TARGET === 'production' ? 'https://mall.dadi18.com' : 'https://test.dadi18.com'
//export const ApiHost = 'http://10.150.192.223:8091'

export const ImageHost = "https://pic.dadi18.com"

export const apiUrl = `${ApiHost}`

export const UploadUrl = `${apiUrl}enterprise/uploadAttach`

//
// export const apiUrl = ID_DEV ? 'http://192.168.2.137:8080/01/':
//   window.location.href.indexOf('store.chepeilong.com') >= 0 ||
//   window.location.href.indexOf('store.chepeitong.com') >= 0?
//     'http://storeapi.chepeilong.com/01/':
//     'http://cptbapi.test.chepeilong.cn/01/'

/*function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
	return response;
  }

  const error = new Error(response.statusText);
  error.response = response;
  throw error;
}*/

const getUrl = (url: string) => {
	return /^http/i.test(url) ? url : `${apiUrl}/oms${url}`

	//return /^http/i.test(url) ? url : `${apiUrl}${url}`
}

export const generateHead = (): { [key: string]: unknown } => {
	return {}
}

const isFormData = (v: any): v is FormData => {
	return Object.prototype.toString.call(v) === "[object FormData]"
}

const isURLSearchParams = (v: any): v is URLSearchParams => {
	return Object.prototype.toString.call(v) === "[object URLSearchParams]"
}

const formAppend = (form: FormData | URLSearchParams, key: string, list: any) => {
	if (Array.isArray(list)) {
		formArrayCheck(form, key, list)
	} else if (isObject(list) && !(list instanceof File)) {
		Object.keys(list).forEach(k => {
			formAppend(form, key, list[k])
		})
	} else {
		form.append(key, list)
	}
}

const formArrayCheck = (form: FormData | URLSearchParams, key: string, list: any[]) => {
	list.forEach((item, index) => {
		if (Array.isArray(item)) {
			formArrayCheck(form, `${key}[${index}]`, item)
		} else if (isObject(item)) {
			Object.keys(item).forEach(k => {
				formAppend(form, `${key}[${index}].${k}`, item[k])
			})
		} else {
			form.append(`${key}[${index}]`, item)
		}
	})
}

export const generateFrom = (options: any, useForm: boolean = false, ignoreObject = false) => {
	return isObject(options)
		? Object.keys(options).reduce(
			(form, key) => {
				if (!isEmpty(options[key])) {
					if (ignoreObject) {
						form.append(key, options[key])
					} else {
						formAppend(form, key, options[key])
					}
				}
				return form
			},
			useForm ? new FormData() : new URLSearchParams()
		)
		: options
}

export const generateHeaderData = (options: any) => {
	let head = generateHead()
	const time = Date.now()
	head.timestamp = time

	return head
}

export const generateGetUrl = (url: string, params?: { [key: string]: unknown }) => {
	let head = generateHead()

	let urlParams: string[] = [`header=${JSONBig.stringify(head)}`]
	if (typeof params === "object") {
		urlParams = [
			...urlParams,
			...Object.keys(params).map(key => {
				return `${key}=${params[key]}`
			}),
		]
	}
	return apiUrl + url + "?" + urlParams.join("&")
}
export const getFileStream = (url: string) => {
	// request('common/getstreamforpath.ashx',{downurl: url}).then(data=>{
	//     return data
	// })
	return generateGetUrl("common/getstreamforpath.ashx", { downurl: url })
}

export function requestPost<T>(url: string, options?: any): Promise<IAjaxResponse<T>> {
	return request<T>(url, options, "post")
}

export function requestGet<T>(url: string, options?: any): Promise<IAjaxResponse<T>> {
	return request<T>(url, options, "get")
}

export function requestDelete<T>(url: string, options?: any): Promise<IAjaxResponse<T>> {
	return request<T>(url, options, "delete")
}

export function requestFormDelete<T>(url: string, options?: any, ignoreObject = false): Promise<IAjaxResponse<T>> {
	return request<T>(url, generateFrom(options, false, ignoreObject), "delete", "normal", "application/x-www-form-urlencoded")
}

export function requestPath<T>(url: string, path?: string | number | null, options?: any, ignoreObject = false): Promise<IAjaxResponse<T>> {
	return request<T>(path ? `${url}/${path}` : url, generateFrom(options, true, ignoreObject), "post", "normal", "application/x-www-form-urlencoded")
}

export function requestFomUrlencoded<T>(url: string, options?: any, ignoreObject = false): Promise<IAjaxResponse<T>> {
	return request<T>(url, generateFrom(options, false, ignoreObject), "post", "normal", "application/x-www-form-urlencoded")
}

export function requestFomData<T>(url: string, options?: any, ignoreObject = false): Promise<IAjaxResponse<T>> {
	return request<T>(url, generateFrom(options, true, ignoreObject), "post", "normal", "application/x-www-form-urlencoded")
}

export function requestDownloadPost<T = Blob>(url: string, options?: any): Promise<IAjaxResponse<T>> {
	return request<T>(url, options, "post", "download")
}

export function requestDownloadGet<T = Blob>(url: string, options?: any): Promise<IAjaxResponse<T>> {
	return request<T>(url, options, "get", "download")
}

export function requestUpload<T>(url: string, options?: any, onPress?: (precent: UploadProgressEvent) => void): Promise<IAjaxResponse<T>> {
	return request<T>(url, options, "post", "normal", "application/x-www-form-urlencoded", onPress)
}

type IFunc<S extends Array<any>, T> = (...attr: S) => Promise<IAjaxResponse<T>>;



export const requestThrottle = <S extends Array<any>, T = any>({
	api,
	times = 5000,
	session = false,
	saveKey
}: {
	api: IFunc<S, T>;
	times?: number;
	saveKey?: string;
	session?: boolean,
}): [IFunc<S, T>, IFunc<S, T>] => {
	let _time = 0;
	let data: any = null;

	const storage = session ? sessionStorage : localStorage;

	if (saveKey) {
		const jsonData = storage.getItem(saveKey);
		if (jsonData) {
			try {
				const parseData = JSON.parse(jsonData);
				_time = parseData.time;
				data = parseData.data;
			} catch (e) {
				console.error(e);
			}
		}
	}

	return [
		async (...attr) => {
			if (!data || (!!times && new Date().getTime() - _time > times)) {
				const res = await api(...attr)
				_time = new Date().getTime();
				data = res;
				if (saveKey) {


					storage.setItem(saveKey, JSON.stringify({ time: _time, data }));
				}
				return data

			} else {
				return data
			}
		},
		async (...attr) => {

			const res = await api(...attr)

			_time = new Date().getTime();
			data = res;
			if (saveKey) {
				storage.setItem(saveKey, JSON.stringify({ time: _time, data }));
			}

			return data
		}
	];
};


export default function request<T>(
	url: string,
	options: any,
	method: "post" | "get" | "delete" = "post",
	type: "normal" | "download" = "normal",
	ContentType: IContentType = "application/json",
	onPress?: (percent: UploadProgressEvent) => void
): Promise<IAjaxResponse<T>> {
	const token = getToken()
	const headers: { "Content-Type": IContentType; token?: string } = { "Content-Type": ContentType }
	let paramData: any

	/* if (!isEmpty(options) && !isObject(options)) {
		url = `${url}/${options}`
		options = null
		ContentType = "application/x-www-form-urlencoded"
	} */

	if (!isEmpty(options)) {
		paramData = options
		if (isFormData(options)) {
			ContentType = "multipart/form-data"
		} else if (isURLSearchParams(options)) {
			ContentType = "application/x-www-form-urlencoded"
		}
	}

	if (token) {
		headers.token = getToken()
	}

	headers["Content-Type"] = ContentType

	//console.log(ContentType, url, headers)

	return new Promise((resolve1, reject1) =>
		Axios({
			url: getUrl(url),
			[method === "get" ? "params" : "data"]: paramData,
			headers,
			method,
			responseType: type === "download" ? "blob" : "text",
			onUploadProgress: ev => {
				onPress?.({ ...ev, percent: ev.total ? (ev.loaded / ev.total) * 100 : 0 })
			},
			transformRequest: data => {
				if (ContentType === "application/json") {
					try {
						return JSONBig({ useNativeBigInt: true }).stringify(data)
					} catch (err) {
						return data
					}
				}
				return data
			},
			transformResponse: data => {
				if (type === "download" && data instanceof Blob) {
					return {
						code: 0,
						message: "操作成功",
						data,
					}
				} else {
					try {
						// 如果转换成功则返回转换的数据结果
						return JSONBig({ useNativeBigInt: true }).parse(data)
					} catch (err) {
						//console.error(err)
						//console.error(data, 'err')
						// 如果转换失败，则包装为统一数据格式并返回
						return data
					}
				}
			},
		})
			.then(function (response) {
				//console.log(response)
				if (!response.data && response.request && response.request.responseText) {
					response.data = JSONBig({ useNativeBigInt: true }).parse(response.request.responseText)
				}

				if (response.data.data instanceof Blob) {
					response.data.data.filename = decodeURIComponent(
						(response.headers["content-disposition"] ?? response.headers["Content-disposition"] ?? response.headers["Content-Disposition"])
							?.split(";")
							.find((item: string) => item.indexOf("filename=") >= 0 || item.indexOf("filename*=") >= 0)
							?.replace("filename=", "")
							.replace("filename*=", "")
							.replace("utf-8''", "")
					)
				}

				if (response.data.code === 1001 && url !== "/admin/login") {
					removeLoading()
					message.error(getToken() ? "登陆信息已过期" : response.data.data || response.data.message)
					setToken("")
					JumpToLogin()
					reject1 && reject1(response.data.message)
					//throw new Error(response.data.message)
				} else {
					resolve1 && resolve1(response.data)
				}

				return response.data
			})
			.catch(function (error) {
				message.error(`接口错误：${error.message}`)
				removeLoading()
				reject1 && reject1(error)
				return error
			})
	)
}
